import { nest } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad/Adfox';

import { NegativeMobile } from 'site/components/Wrappers';

export const TopBanner = bindProps({
  name: 'TopBanner',
  ownerId: 406871,
  params: {
    pp: 'g',
    ps: 'fptc',
    p2: 'y',
  },
})(Ad);

export const InPage = bindProps({
  name: 'InPage',
  ownerId: 406871,
  params: {
    pp: 'h',
    ps: 'fptc',
    p2: 'hojx',
  },
})(nest(NegativeMobile, Ad));

export const Listing = bindProps({
  name: 'Listing',
  ownerId: 406871,
  params: {
    pp: 'g',
    ps: 'fptc',
    p2: 'y',
    insertAfter: 'undefined',
    insertPosition: '0',
    stick: false,
    stickTo: 'auto',
    stickyColorScheme: 'light',
  },
})(Ad);
