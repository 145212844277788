import queryString from 'core/libs/query-string';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';


const transformRequestData = data => {
  return {
    content: denormalizeData(data),
    meta: {
      filtered_count: data?.meta.filtered_count || data?.meta.search_results?.total_found || 0,
    },
  };
};

export default props => {
  const {
    disabledFilters = [],
    limit,
    match,
    location: { search },
  } = props;

  const isTagPage = match.params.tagType === 'label';
  const queryFilters = queryString.parse(search);
  const {
    type, // eslint-disable-line
    page, // eslint-disable-line
    query,
    first_char: char,
    ...otherFilters
  } = queryFilters;

  const commonRequestParams = {
    limit,
    topic_type: 'artist_group,artist_person',
    offset: getOffset(search, limit),
    include: 'image,tags',
  };

  if (query) {
    return {
      queryKey: ['bebopApi', 'getSearch', {
        ...commonRequestParams,
        query: decodeURIComponent(query),
        title_only: 1,
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      select: transformRequestData,
    };
  }

  const otherTags = Object.keys(otherFilters).reduce((result, key) => {
    const value = otherFilters[key];
    if (disabledFilters.includes(key) || !value) return result;
    return result + value + ',';
  }, '');

  const tag = isTagPage
    ? `${otherTags}${match.params.level_2}`
    : otherTags;

  return {
    queryKey: ['bebopApi', 'getTopics', {
      ...commonRequestParams,
      fields: 'link,headline,list_headline',
      with_filtered_count: 1,
      all_tags: tag,
      sort: 'list_headline,headline',
      ...char && { first_char: decodeURIComponent(char) },
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    select: transformRequestData,
  };
};
